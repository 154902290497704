import React, { Component } from "react"
import { navigate } from "gatsby"
import SEO from "./seo"
import UserContext from "../context/UserContext"
import DefaultLayout from "../layouts/defaultLayout"
import ConfirmGIF from "./confirmGIF"

export class Confirm extends Component {
  static contextType = UserContext

  continue = e => {
    e.preventDefault()
    this.props.handleChange(e)
    this.props.nextStep()
  }

  back = e => {
    this.props.handleChange(e)
    this.props.prevStep()
  }

  componentDidMount() {
    setTimeout(
      () =>
        navigate("/personalized-projects", { state: this.context.wizardData }),
      3000
    )
  }

  render() {
    return (
      <DefaultLayout>
        <SEO title="Confirm data" />
        <ConfirmGIF />
      </DefaultLayout>
    )
  }
}

export default Confirm
