import React, { useContext } from "react"
import DefaultLayout from "../layouts/defaultLayout"
import SEO from "./seo"
import UserContext from "../context/UserContext"

const Question = ({ children, title, seo, slug, options, nextStep }) => {
  const context = useContext(UserContext)
  const onClickHandler = (e, projects) => {
    e.preventDefault() // Prevent default button action
    context.handleWizardChange(e) // Makes sure context is updated with value of button
    context.handleProjectRatingsChange(projects)
    nextStep()
  }
  return (
    <DefaultLayout>
      <SEO title={seo} />
      <div>
        {children}
        <h2 className="text-3xl font-medium">{title}</h2>
        <br />
        <br />
        <div className="question">
          {options.map(option => {
            return (
              <button
                key={option.id}
                className="button button-wizard"
                value={option.identifier}
                name={slug}
                onClick={e => onClickHandler(e, option.projectRatings)}
                type="button"
              >
                {option.text}
              </button>
            )
          })}
        </div>
      </div>
    </DefaultLayout>
  )
}

export default Question
