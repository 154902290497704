import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const ConfirmGIF = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulTreeGif {
        gif {
          file {
            url
          }
          description
        }
      }
    }
  `)

  return (
    <div>
      <h3 style={{ textAlign: "center" }} className="text-3xl font-medium">
        {data.contentfulTreeGif.gif.description}
      </h3>
      <img
        style={{
          marginLeft: "37.5%",
          marginRight: "50%",
          width: "25%",
          backgroundColor: "white",
          background: "white",
        }}
        src={data.contentfulTreeGif.gif.file.url}
        alt="tree gif"
      ></img>
    </div>
  )
}
export default ConfirmGIF
