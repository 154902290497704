import React, { Component } from "react"
import Dots from "../components/dots"
import Modal from "react-responsive-modal"
import SEO from "../components/seo"
import Confirm from "../components/confirm"
import Question from "../components/question"
import UserContext from "../context/UserContext"
import { graphql } from "gatsby"

export class MainForm extends Component {
  static contextType = UserContext

  state = {
    step: 1,
    modalIsOpen: false,
  }

  componentDidMount() {
    this.setState({
      modalIsOpen: true,
    })
    this.context.resetProjectRatings()
  }

  // Method to proeed to the next step
  nextStep = () => {
    const { step } = this.state
    this.setState({
      step: step + 1,
    })
  }

  // Method to go to the previous step
  prevStep = () => {
    const { step } = this.state
    this.setState({
      step: step - 1,
    })
  }

  render() {
    const { step } = this.state

    const numDots = 5

    switch (step) {
      case 1:
        return (
          <div>
            {this.props.data.allContentfulQuestions.nodes.map(question => {
              if (question.order == 1) {
                return (
                  <Question
                    key={question.id}
                    title={question.title}
                    slug={question.slug}
                    seo={question.seo}
                    options={question.questionOptions}
                    nextStep={this.nextStep}
                  >
                    <Dots numDots={numDots} curStep={this.state.step}></Dots>
                  </Question>
                )
              }
            })}
            <Modal
              className="journey-modal"
              open={this.state.modalIsOpen}
              onClose={() => {
                this.setState({ modalIsOpen: false })
              }}
              center
              styles={modalStyles}
            >
              <br />
              <br />
              <h2>
                Congratulations on taking the first step on your climate
                journey!
              </h2>
              <br />
              <h3>
                A few short questions will help us tailor suggestions for you.
              </h3>
              <br />
              <button
                className="button-modal-continue"
                onClick={() => this.setState({ modalIsOpen: false })}
              >
                Continue
              </button>
            </Modal>
          </div>
        )
      case 2: {
        return (
          <div>
            {this.props.data.allContentfulQuestions.nodes.map(question => {
              if (question.order == 2) {
                return (
                  <Question
                    key={question.id}
                    title={question.title}
                    slug={question.slug}
                    seo={question.seo}
                    options={question.questionOptions}
                    nextStep={this.nextStep}
                  >
                    <Dots numDots={numDots} curStep={this.state.step}></Dots>
                  </Question>
                )
              }
            })}
          </div>
        )
      }
      case 3: {
        return (
          <div>
            {this.props.data.allContentfulQuestions.nodes.map(question => {
              if (question.order == 3) {
                return (
                  <Question
                    key={question.id}
                    title={question.title}
                    slug={question.slug}
                    seo={question.seo}
                    options={question.questionOptions}
                    nextStep={this.nextStep}
                  >
                    <Dots numDots={numDots} curStep={this.state.step}></Dots>
                  </Question>
                )
              }
            })}
          </div>
        )
      }
      case 4: {
        return (
          <div>
            {this.props.data.allContentfulQuestions.nodes.map(question => {
              if (question.order == 4) {
                return (
                  <Question
                    key={question.id}
                    title={question.title}
                    slug={question.slug}
                    seo={question.seo}
                    options={question.questionOptions}
                    nextStep={this.nextStep}
                  >
                    <Dots numDots={numDots} curStep={this.state.step}></Dots>
                  </Question>
                )
              }
            })}
          </div>
        )
      }
      case 5: {
        return (
          <div>
            {this.props.data.allContentfulQuestions.nodes.map(question => {
              if (question.order == 5) {
                return (
                  <Question
                    key={question.id}
                    title={question.title}
                    slug={question.slug}
                    seo={question.seo}
                    options={question.questionOptions}
                    nextStep={this.nextStep}
                  >
                    <Dots numDots={numDots} curStep={this.state.step}></Dots>
                  </Question>
                )
              }
            })}
          </div>
        )
      }
      case 6:
        return <Confirm nextStep={this.nextStep} prevStep={this.prevStep} />
      case 7:
        // const numDots = step - 2
        return <h1>Success</h1>

      default:
        return (
          <div>
            <SEO title="Survey wizard" />
          </div>
        )
    }
  }
}

const modalStyles = {
  modal: {
    borderRadius: "0.45em",
    // border: "3px solid #01b76b",
    maxWidth: "500px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
}

export const query = graphql`
  query Questions {
    allContentfulQuestions(sort: { fields: order }) {
      nodes {
        id
        title
        slug
        seo
        order
        questionOptions {
          id
          text
          identifier
          projectRatings {
            id
            title
            rating
            project {
              name
              id
              contentful_id
            }
          }
        }
      }
    }
  }
`

export default MainForm
